import React from 'react'
import { PageProps, navigate } from 'gatsby'
import { Head, Hero, Button, Footer } from '~/components'
import * as st from '~/assets/styl/NotFound.module.styl'
import Banner from '~/assets/img/Agradecimento.jpg'

const Success = ({ location }: PageProps) => (
  <>
    <Head
      location={location}
      title={'Mensagem enviada - ' + process.env.GATSBY_SITE_NAME}
    />
    <Hero
      title="Mensagem enviada"
      description={
        'Sua mensagem foi enviada com sucesso.\nEm breve entraremos em contato.'
      }
      image={Banner}
      type="notFound"
    >
      <div className={st.btns}>
        <Button onClick={() => navigate(-1)} className={st.btn}>
          Voltar
        </Button>
      </div>
    </Hero>
    <Footer />
  </>
)

export default Success
